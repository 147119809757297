import { useMemo } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./HistoryEntry.module.css";
import { Delete32Regular, Delete32Filled, bundleIcon } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import { ChatAppHistoryEntry } from "../../api";

import { format } from 'date-fns';

const DeleteButton = bundleIcon(
    Delete32Filled,
    Delete32Regular
  );

interface Props {
    entry: ChatAppHistoryEntry;
    isLoading: boolean;
    onHistoryEntryClicked: (conversationId: string) => void;
    onDeleteHistoryEntryClicked: (conversationId: string) => void;
}

function trimStringToWords(str: string, numberOfWords: number) {
    // replace newlines with spaces
    str = str.replace(/\n/g, ' ');
    const words = str.split(/\s+/); // Split the string into words
    if (words.length > numberOfWords) {
      return words.slice(0, numberOfWords).join(' ') + '...'; // Join the first 20 words and add ellipsis
    } else {
      return str; // Return the original string if it's 20 words or less
    }
  }

export const HistoryEntry = ({ entry, isLoading, onHistoryEntryClicked, onDeleteHistoryEntryClicked }: Props ) => {

    const content = entry.content;

    const sanitizedContentHtml = DOMPurify.sanitize(trimStringToWords(content, 10)) + " (" + format(entry.last_updated_at, 'yyyy-MM-dd HH:mm') + ")";


    return (
        <Stack className={`${styles.historyContainer}`} verticalAlign="space-between">

            <Stack.Item grow>
                <div className={styles.historyText}>
                    {isLoading ? (
                    <a key={entry.conversation_id} className={styles.disabledHistoryEntryLink}>{sanitizedContentHtml}</a> ) : (
                    <a key={entry.conversation_id} className={styles.historyEntryLink} onClick={() => onHistoryEntryClicked(entry.conversation_id)}>{sanitizedContentHtml}</a>                    
                    )}

                    
                    <Button icon={<DeleteButton />} size="small" appearance="subtle" 
                     onClick={() => onDeleteHistoryEntryClicked(entry.conversation_id)} disabled={isLoading} iconPosition="before" />
                  
                </div>      
            </Stack.Item>

        </Stack>
    );
};
