import { Outlet, NavLink, Link } from "react-router-dom";

import styles from "./Footer.module.css"

import hcl_logo from "../../assets/hcl_logo.png";

export const Footer = (props: any) => {




    return (
        <div className={styles.footer_container}>
            <div className={styles.child}>
            <img src={hcl_logo} alt="" className={styles.logo} />

            </div>
            <div className={styles.child}>
                <span className={styles.copyrightText}>
                Copyright © 2024 HCL Technologies Limited | 

                </span>
                <span className={styles.termsText}>

                 Terms for Use
                </span>
                 </div>
            <div className={styles.child}></div>
            
        </div>
    );
};

