import { bundleIcon, AddCircle32Filled, AddCircle32Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import styles from "./ClearChatButton.module.css";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}

const ChatBubbleQuesionButton = bundleIcon(
    AddCircle32Filled,
    AddCircle32Regular
  );

export const ClearChatButton = ({ className, disabled, onClick }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            
            <Button className={styles.btnOverride} icon={<ChatBubbleQuesionButton />} appearance="subtle" disabled={disabled} onClick={onClick} iconPosition="before" size="large">
                <div className={styles.buttonText}>{"New Conversation"}</div>
            </Button>
        </div>
    );
};
