import React, { createContext, useContext, ReactNode } from 'react';
import { Config } from './api';

export const ConfigContext = createContext<Config | undefined>(undefined);


export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
      throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
}

// ConfigProvider component type definition
interface ConfigProviderProps {
  children: ReactNode;
  config: Config; 
}

// ConfigProvider component
export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children, config }) => {
  return (
      <ConfigContext.Provider value={config}>
          {children}
      </ConfigContext.Provider>
  );
};