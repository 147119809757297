
import { ChatAppRequest, Config } from "./models";
import { fetchEventSource } from "@microsoft/fetch-event-source";


let configPromise: Promise<Config> | null = null;

function getConfig(): Promise<Config> {
    if (!configPromise) {
        configPromise = configApi().catch(error => {
            configPromise = null;
            throw error;
        });
    }
    return configPromise;
}

export function getHeaders(additionalHeaders?: Record<string, string>): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    return headers;
}

export async function configApi(): Promise<Config> {
    const response = await fetch(`/config`, {
        method: "GET",
        headers: getHeaders()
    })

    if (response.status === 401) {
        const shouldRefresh = window.confirm("Your session has expired. Click OK to refresh the page.");
        if (shouldRefresh) {
            window.location.reload();
        }
        throw new Error("Session expired");
    }

    return (await response.json() as Config);

}

export async function chatHistoryListApi(): Promise<Response> {
    const response = await getConfig().then(config => fetch(`${config.backend_api_base_url}/chat/history`, {
        method: "GET",
        credentials: 'include',
        headers: getHeaders()
    }));

    if (response.status === 401) {
        const shouldRefresh = window.confirm("Your session has expired. Click OK to refresh the page.");
        if (shouldRefresh) {
            window.location.reload();
        }
        throw new Error("Session expired");
    }

    return response;

}

export async function chatHistoryEntriesApi(conversationId: string): Promise<Response> {
    const response = await getConfig().then(config => fetch(`${config.backend_api_base_url}/chat/history/${conversationId}`, {
        method: "GET",
        credentials: 'include',
        headers: getHeaders()
    }));

    if (response.status === 401) {
        const shouldRefresh = window.confirm("Your session has expired. Click OK to refresh the page.");
        if (shouldRefresh) {
            window.location.reload();
        }
        throw new Error("Session expired");
    }

    return response;
}

export async function deleteHistoryEntriesApi(conversationId: string): Promise<Response> {
    const response = await getConfig().then(config => fetch(`${config.backend_api_base_url}/chat/history/${conversationId}`, {
        method: "DELETE",
        credentials: 'include',
        headers: getHeaders()
    }));

    if (response.status === 401) {
        const shouldRefresh = window.confirm("Your session has expired. Click OK to refresh the page.");
        if (shouldRefresh) {
            window.location.reload();
        }
        throw new Error("Session expired");
    }

    return response;
}

export async function chatStreamingApi(request: ChatAppRequest, onMessage: any): Promise<void> {
    await getConfig().then(config => fetchEventSource(`${config.backend_api_base_url}/chat`, {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify(request),
        headers: getHeaders({ "Content-Type": "application/json" }),
        onmessage(event) {
            onMessage(request.user_input, event.data);
        },
        onclose() {
            console.log("Connection closed by the server");
        },
        onerror(err) {
            console.log("Cannot stream chat", err);
            throw err;
        },
        openWhenHidden: true
    }));
}

export async function chatApi(request: ChatAppRequest): Promise<Response> {
    const response = await getConfig().then(config => fetch(`${config.backend_api_base_url}/chat`, {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify(request),
        headers: getHeaders()
    }));

    if (response.status === 401) {
        const shouldRefresh = window.confirm("Your session has expired. Click OK to refresh the page.");
        if (shouldRefresh) {
            window.location.reload();
        }
        throw new Error("Session expired");
    }

    return response;
}