import { useRef, useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { Checkbox, Panel, DefaultButton, TextField, SpinButton, Slider, on, ChoiceGroup, IChoiceGroupOption, ComboBox, IComboBox, IComboBoxOption } from "@fluentui/react";

import { Info32Regular } from "@fluentui/react-icons";

import styles from "./Chat.module.css";
import { Tooltip, Button } from "@fluentui/react-components";

import { HistoryEntry } from "../../components/HistoryEntry";
import {
    chatHistoryListApi,
    chatHistoryEntriesApi,
    chatStreamingApi,
    Config,
    chatApi,
    configApi,
    RetrievalMode,
    ChatAppResponse,
    ChatAppResponseOrError,
    ChatAppHistoryListResponseOrError,
    ChatAppHistoryMessagesResponseOrError,
    ChatAppHistoryEntry,
    ChatAppRequest,
    // ResponseMessage,
    VectorFieldOptions,
    GPT4VInput,
    ChatAppHistoryListResponse,
    deleteHistoryEntriesApi,
    ChatAppEvent
} from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { Layout } from "../layout"
import { ConfigureAnswer } from "../../components/ConfigureAnswer"
// import { useLogin, getToken, isLoggedIn, requireAccessControl } from "../../authConfig";
import { VectorSettings } from "../../components/VectorSettings";
// import { useMsal } from "@azure/msal-react";
// import { TokenClaimsDisplay } from "../../components/TokenClaimsDisplay";
import { GPT4VSettings } from "../../components/GPT4VSettings";
import { parse, set } from "date-fns";
import { a } from "@react-spring/web";
import { Footer } from "../../components/Footer/Footer";


// import { useConfig } from '../../configContext';

import { auth} from "../../firebase";
import {  signInWithEmailAndPassword  } from 'firebase/auth';



const Chat = () => {
    // const config = useConfig();
    const [selectedFollowUpQuestion, setSelectedFollowUpQuestion] = useState("");

    const [streamingAnswer, setStreamingAnswer] = useState("");

    const [historyListEntries, setHistoryListEntries] = useState<ChatAppHistoryEntry[]>([]);

    // const [historyMessages, setHistoryMessages] = useState<ChatAppHistoryEntry[]>([]);
    const [searchParams] = useSearchParams();
    const [conversationId, setConversationId] = useState<string>();
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [temperature, setTemperature] = useState<number>(0.3);

    const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [shouldStream, setShouldStream] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);
    const [vectorFieldList, setVectorFieldList] = useState<VectorFieldOptions[]>([VectorFieldOptions.Embedding]);
    const [useOidSecurityFilter, setUseOidSecurityFilter] = useState<boolean>(false);
    const [useGroupsSecurityFilter, setUseGroupsSecurityFilter] = useState<boolean>(false);
    const [gpt4vInput, setGPT4VInput] = useState<GPT4VInput>(GPT4VInput.TextAndImages);
    const [useGPT4V, setUseGPT4V] = useState<boolean>(false);

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [showGPT4VOptions, setShowGPT4VOptions] = useState<boolean>(false);
    const [showSemanticRankerOption, setShowSemanticRankerOption] = useState<boolean>(false);
    const [settingConfiguration, setSettingconfiguration] = useState<any>(null)

    const [inputFocus, setInputFocus] = useState<any>(false)
    // const [showVectorOption, setShowVectorOption] = useState<boolean>(false);


    const handleFollowupQuestionClick = (question: string) => {
        setSelectedFollowUpQuestion(question);
    };

    useEffect(() => {
        setShouldStream(true);
    }, []);

    const applyFunction = (evt: any) => {
        setSettingconfiguration(evt)
        setIsConfigPanelOpen(false)
    }

    const makeApiRequest = async (question: string, filters?: any) => {

        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const request: ChatAppRequest = {
                user_input: question,
                conversation_id: conversationId,
                params: {
                    search_kwargs: {
                        k: settingConfiguration?.retrieveCount,
                        score_threshold: settingConfiguration?.relevanceScore,
                        rag_fusion_enabled: settingConfiguration?.enableRagFusion,
                        max_new_questions_for_rag_fusion: settingConfiguration?.maxNewQuestionsForRagFusion,
                        chunking_strategy: settingConfiguration?.documentChunkingMethod,
                    },
                    model_params_kwargs: {
                        max_tokens: settingConfiguration?.maxResponseTokens,
                        selected_role: settingConfiguration?.enableSpecialists ? (settingConfiguration?.selectSpecialistsMethod === "auto" ? "" : settingConfiguration?.selectedSpecialist) : "default",
                    }
                },
                filters: filters,
                streaming: shouldStream,

            };

            if (shouldStream) {
                setIsStreaming(true);
                setStreamedAnswers([...answers]);
                await chatStreamingApi(request, onMessageFromChatStreaming);
            } else {
                const response = await chatApi(request);
                if (!response.body) {
                    throw Error("No response body");
                }

                const parsedResponse: ChatAppResponseOrError = await response.json();
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }
                setAnswers([...answers, [question, parsedResponse as ChatAppResponse]]);
                setConversationId(parsedResponse.conversation_id);
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsStreaming(false);
            setIsLoading(false);
        }
    };


    const makeHistoryListApiRequest = async () => {

        try {

            const response = await chatHistoryListApi();
            if (response.body) {

                const parsedResponse: ChatAppHistoryListResponseOrError = await response.json();
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }
                setHistoryListEntries(parsedResponse.records as ChatAppHistoryEntry[]);
                // }
            }
        } catch (e) {
            setError(e);
        } finally {
        }
    };


    const makeHistoryEntriesApiRequest = async (selectedConversationId: string) => {
        try {
            const response = await chatHistoryEntriesApi(selectedConversationId);
            if (response.body) {

                const parsedResponse: ChatAppHistoryMessagesResponseOrError = await response.json();
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }

                const historyMessages = [...parsedResponse.records as ChatAppHistoryEntry[]];



                for (const message of historyMessages.reverse()) {
                    if (message.type === "human") {
                        lastQuestionRef.current = message.content;
                        break;
                    }
                }

                if (historyMessages.length > 2) {
                    lastQuestionRef.current = historyMessages[historyMessages.length - 2].content;
                }

                setAnswers(convertHistoryMessagesToAnswers(parsedResponse.records as ChatAppHistoryEntry[]));

                setConversationId(selectedConversationId);
                // }
            }
        } catch (e) {
            setError(e);
        } finally {
        }

    };

    const convertHistoryMessagesToAnswers = (historyMessages: ChatAppHistoryEntry[]) => {

        let answers: [user: string, response: ChatAppResponse][] = [];

        let current_question: string = "";
        historyMessages.map((entry) => {

            if (entry.type === "ai") {
                const parsed = JSON.parse(entry.content);

                answers.push([current_question, parsed as ChatAppResponse]);
                current_question = "";

                return parsed as ChatAppResponse;
            } else {
                current_question = entry.content;
                return entry.content
            }
        });
        return answers;
    }

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setStreamedAnswers([]);
        setStreamingAnswer("");
        setIsLoading(false);
        setIsStreaming(false);
        setConversationId(undefined);
        setInputFocus(true)
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading, isStreaming]);
    // useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [streamedAnswers]);
    useEffect(() => {
        makeHistoryListApiRequest();
    }, [answers]);

    const onMessageFromChatStreaming = (question: string, event: any) => {

        if (event !== undefined && event.trim() !== '') {
            let parsedResponse: ChatAppEvent = {};
            try {
                parsedResponse = JSON.parse(event);
            } catch (e) {
                console.error("Error parsing an event:", e);
                console.error("Event:", event);
            }
            if (parsedResponse.is_final_message === true) {
                setStreamedAnswers([]);
                setStreamingAnswer("");
                setAnswers([...answers, [question, parsedResponse.data as ChatAppResponse]]);
                setConversationId(parsedResponse.conversation_id);

            } else if (parsedResponse.is_error) {
                throw Error(parsedResponse.error);
            } else {
                if (parsedResponse.data === "KEEPALIVE") {
                    console.log("Received keepalive message");
                } else {
                    setIsLoading(false);
                    setStreamingAnswer(prevStreamingAnswer => {
                        const newAnswer = prevStreamingAnswer + parsedResponse.data;
                        setStreamedAnswers([...answers, [question, {
                            answer: newAnswer,
                            citations: [],
                            follow_up_questions: [],
                            conversation_id: parsedResponse.conversation_id
                        } as ChatAppResponse]]);

                        return newAnswer;
                    })
                }
            }
        }
    }

    const onHistoryEntryClicked = (conversationId: string) => {
        makeHistoryEntriesApiRequest(conversationId);
    }

    const onDeleteHistoryEntryClicked = (deleteConversationId: string) => {
        deleteHistoryEntriesApi(deleteConversationId).then(() => {
            if (deleteConversationId === conversationId) {
                clearChat();
            }

            // refresh the list after deleteHistoryEntriesApi is complete
            makeHistoryListApiRequest();
        }).catch((error) => {
            // Handle any errors from deleteHistoryEntriesApi here
            console.error("Error deleting history entry:", error);
        });
    }

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onTemperatureChange = (
        newValue: number,
        range?: [number, number],
        event?: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent | React.KeyboardEvent
    ) => {
        setTemperature(newValue);
    };



    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onShouldStreamChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setShouldStream(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onUseOidSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseOidSecurityFilter(!!checked);
    };

    const onUseGroupsSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseGroupsSecurityFilter(!!checked);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const handleCallback = (evt: any) => {
        setIsConfigPanelOpen(!isConfigPanelOpen)
    };



    return (
        <>
            <Layout parentCallback={(evt: any) => handleCallback(evt)} isLoading={isLoading} />


            <div className={styles.container}>
                <div className={styles.chatRoot}>
                    <div className={`${styles.chatHistoryContainer}`}>
                        <ClearChatButton onClick={clearChat} disabled={isLoading} />
                        <div className={styles.chatHistoryRoot}>
                            <div>
                                {
                                    historyListEntries.map((entry, index) => (
                                        <div key={"history_" + index}>
                                            <HistoryEntry entry={entry} isLoading={isLoading} onHistoryEntryClicked={onHistoryEntryClicked} onDeleteHistoryEntryClicked={onDeleteHistoryEntryClicked} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.chatContainer}`}>
                        {/* <div className={styles.commandsContainer}>
                            <SettingsButton isLoading={isLoading} className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
                        </div> */}
                        <div className={styles.chatMessageStream}>
                            {isStreaming &&
                                streamedAnswers.map((streamedAnswer, index) => (
                                    <div key={index}>
                                        <UserChatMessage message={streamedAnswer[0]} />
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                isStreaming={true}
                                                isLoading={isLoading}
                                                isLast={streamedAnswers.length - 1 === index}
                                                key={index}
                                                answer={streamedAnswer[1]}
                                                isSelected={false}
                                                onCitationClicked={c => onShowCitation(c, index)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                onFollowupQuestionClicked={q => handleFollowupQuestionClick(q)}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                            />
                                        </div>
                                    </div>
                                ))}
                            {!isStreaming &&
                                answers.map((answer, index) => (
                                    <div key={index}>
                                        <UserChatMessage message={answer[0]} />
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                isStreaming={false}
                                                isLast={streamedAnswers.length - 1 === index}
                                                key={index}
                                                answer={answer[1]}
                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                onCitationClicked={c => onShowCitation(c, index)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                onFollowupQuestionClicked={q => handleFollowupQuestionClick(q)}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                            />
                                        </div>
                                    </div>
                                ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>


                        <div className={styles.chatInput}>
                            <QuestionInput
                                clearOnSend
                                placeholder="Type a new question (e.g. How to measure bp at home?)"
                                disabled={isLoading}
                                onSend={question => makeApiRequest(question)}
                                initQuestion={selectedFollowUpQuestion}
                                inputFocus={inputFocus}
                            />
                        </div>
                    </div>

                    {/* {answers.length > 0 && activeAnalysisPanelTab && (
                        <AnalysisPanel
                            className={styles.chatAnalysisPanel}
                            activeCitation={activeCitation}
                            onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                            citationHeight="810px"
                            answer={answers[selectedAnswer][1]}
                            activeTab={activeAnalysisPanelTab}
                        />
                    )} */}

                    {isConfigPanelOpen ? (
                        <>
                            <ConfigureAnswer isOpen={isConfigPanelOpen} settingConfiguration={settingConfiguration} isLoading={isLoading} onCancelClick={() => setIsConfigPanelOpen(false)} applyClick={(evt: any) => applyFunction(evt)} />
                        </>
                    )
                        : <></>
                    }


                </div>
            </div>

            <Footer></Footer>
        </>
    );
};

export default Chat;
