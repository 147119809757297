export const enum RetrievalMode {
    Hybrid = "hybrid",
    Vectors = "vectors",
    Text = "text"
}

export const enum GPT4VInput {
    TextAndImages = "textAndImages",
    Images = "images",
    Texts = "texts"
}

export const enum VectorFieldOptions {
    Embedding = "embedding",
    ImageEmbedding = "imageEmbedding",
    Both = "both"
}

export type ChatAppRequestOverrides = {
    retrieval_mode?: RetrievalMode;
    semantic_ranker?: boolean;
    semantic_captions?: boolean;
    exclude_category?: string;
    top?: number;
    temperature?: number;
    prompt_template?: string;
    prompt_template_prefix?: string;
    prompt_template_suffix?: string;
    suggest_followup_questions?: boolean;
    use_oid_security_filter?: boolean;
    use_groups_security_filter?: boolean;
    use_gpt4v?: boolean;
    gpt4v_input?: GPT4VInput;
    vector_fields: VectorFieldOptions[];
};

// export type ResponseMessage = {
//     content: string;
//     role: string;
// };

export type Thoughts = {
    title: string;
    description: any; // It can be any output from the api
    props?: { [key: string]: string };
};

// export type ResponseContext = {
//     data_points: string[];
//     followup_questions: string[] | null;
//     thoughts: Thoughts[];
// };

// export type ResponseChoice = {
    // index: number;
    // message: ResponseMessage;
    // context: ResponseContext;
    // session_state: any;
// };


export type ChatAppCitations = {
    citation_index: number;
    name: string;
    source_url: string;
    page_number?: number;
    page_chunk?: string;
    content?: string;
};

export type ChatAppResponseOrError = {

    answer: string;
    citations: ChatAppCitations[];
    conversation_id: string;

    error?: string;
};

export type ChatAppHistoryEntry = {
    conversation_id: string;
    type: string;
    last_updated_at: Date;
    content: string;
}

export type ChatAppHistoryListResponse = {
    records: ChatAppHistoryEntry[];
};

export type ChatAppHistoryListResponseOrError = {
    records: ChatAppHistoryEntry[];

    error?: string;
};

export type ChatAppHistoryMessagesResponse = {
    records: ChatAppHistoryEntry[];
};

export type ChatAppHistoryMessagesResponseOrError = {
    records: ChatAppHistoryEntry[];

    error?: string;
};

export type ChatAppResponse = {

    answer: string;
    citations: ChatAppCitations[];
    follow_up_questions: string[];
    conversation_id: string;
};

export type ChatAppRequestContext = {
    overrides?: ChatAppRequestOverrides;
};

export type ChatAppRequest = {
    user_input: string;
    conversation_id?: string;
    params?: any;
    filters?: any;
    streaming: boolean;
};

export type ChatAppEvent = {
    is_final_message?: boolean;
    is_error?: boolean;
    data?: any; 
    conversation_id?: string;
    error?: string;
}

export type Config = {
    // showGPT4VOptions: boolean;
    // showSemanticRankerOption: boolean;
    showVectorOption: boolean;

    backend_api_base_url: string;
};
