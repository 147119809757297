import { useMemo } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";
import {  AnswerLoading } from "../../components/Answer";
import { BlinkingDot } from "../../components/BlinkingDot";

import { ChatAppResponse } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import remarkGfm from 'remark-gfm'

interface Props {
    answer: ChatAppResponse;
    isSelected?: boolean;
    isLoading?: boolean;
    isLast?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked: (question: string) => void;
    showFollowupQuestions?: boolean;
}

export const Answer = ({
    answer,
    isSelected,
    isStreaming,
    isLast,
    isLoading,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions
}: Props) => {

    const followupQuestions: string[] = answer.follow_up_questions;
    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer, isStreaming, onCitationClicked), [answer]);

    const citations = answer.citations;

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item grow>
                <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} className={ styles.markdownAnswer }>{parsedAnswer}</ReactMarkdown> {!isLoading && isStreaming && isLast && <BlinkingDot/>}             
            </Stack.Item>

            {!!(citations?.length ?? 0) && (
                <Stack.Item>
                    <Stack horizontal={false} wrap tokens={{ childrenGap: 5 }}>
                        <div><span className={styles.citationLearnMore}>Learn more:</span></div>
                        {citations.map((x, i) => {
                            // const path = getCitationFilePath(x);                            
                            return (
                                <a key={x.citation_index} className={styles.citation} title={x.name} href={x.source_url} target="_blank">
                                    {`${x.citation_index}. ${x.name}`} { x.page_chunk ? `(${x.page_chunk})` : "" }
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!followupQuestions?.length && (
                <Stack.Item>
                    <Stack horizontal={false} wrap className={`${!!(citations?.length ?? 0) ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>

                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
};
