import { Settings24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton, Slider, on, ChoiceGroup, IChoiceGroupOption, ComboBox, IComboBox, IComboBoxOption } from "@fluentui/react";

import styles from "./ConfigureAnswer.module.css";
import { useState, useEffect } from "react";

interface Props {
    className?: string;
    isLoading?: boolean;
    isOpen?: boolean;
    onCancelClick: () => void;

}



export const ConfigureAnswer = (props: any) => {
    const [enableSpecialists, setEnableSpecialists] = useState<boolean>(false);
    const [selectSpecialistsMethod, setSelectSpecialistsMethod] = useState<string>("manual");
    const [selectedSpecialist, setSelectedSpecialist] = useState<string>("default");
    const [enableRagFusion, setEnableRagFusion] = useState<boolean>(false);
    const [showDeveloperOptions, setShowDeveloperOptions] = useState<boolean>(false);
    const [maxResponseTokens, setMaxResponseTokens] = useState<number>(props.settingConfiguration?.maxResponseTokens || 4096);
    const [relevanceScore, setRelevanceScore] = useState<number>(props.settingConfiguration?.relevanceScore || 0.8);
    const [documentChunkingMethod, setDocumentChunkingMethod] = useState<string>("all_docs_chunksize2000");
    const [maxNewQuestionsForRagFusion, setMaxNewQuestionsForRagFusion] = useState<number>(props.settingConfiguration?.maxNewQuestionsForRagFusion || 5);
    const [retrieveCount, setRetrieveCount] = useState<number>(props.settingConfiguration?.retrieveCount || 3);

    const checkBoxStyles = {
        text: {
            fontWeight: "bold",
        }
    };


    useEffect(() => {

        if (props.settingConfiguration) {
            setEnableSpecialists(props.settingConfiguration.enableSpecialists)
            setSelectSpecialistsMethod(props.settingConfiguration.selectSpecialistsMethod)
            setSelectedSpecialist(props.settingConfiguration.selectedSpecialist)
            setEnableRagFusion(props.settingConfiguration.enableRagFusion)
            setShowDeveloperOptions(props.settingConfiguration.showDeveloperOptions)
            setDocumentChunkingMethod(props.settingConfiguration.documentChunkingMethod)
        }
    }, []);

    const onEnableSpecialistsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setEnableSpecialists(!!checked);
    };
    const onSelectSpecialistsMethodChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
        if (option) setSelectSpecialistsMethod(option.key);

    };
    const onSelectedSpecialistChange = (ev?: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => {
        if (option) setSelectedSpecialist(option.key as string);

    }
    const onEnableRagFusionChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setEnableRagFusion(!!checked);
    };

    const onShowDeveloperOptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setShowDeveloperOptions(!!checked);
    };

    const onMaxResponseTokensChange = (
        newValue: number,
        range?: [number, number],
        event?: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent | React.KeyboardEvent
    ) => {
        setMaxResponseTokens(newValue || 4096);
    };

    const onRelevanceScoreChange = (
        newValue: number,
        range?: [number, number],
        event?: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent | React.KeyboardEvent
    ) => {
        setRelevanceScore(newValue || 0.8);
    };

    const onDocumentChunkingMethodChange = (ev?: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => {
        if (option) {
            setDocumentChunkingMethod(option.key as string);
        }
    }

    const onMaxNewQuestionsForRagFusionChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setMaxNewQuestionsForRagFusion(parseInt(newValue || "5"));
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onApplyClick = () => {

        props.applyClick(
            {
                enableSpecialists,
                selectSpecialistsMethod,
                selectedSpecialist,
                enableRagFusion,
                showDeveloperOptions,
                maxResponseTokens,
                relevanceScore,
                maxNewQuestionsForRagFusion,
                documentChunkingMethod,
                retrieveCount
            }
        )
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.heading}>
                    Configure Answer Generation
                </div>

                <div>
                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Max number of search results:"
                        min={2}
                        max={20}
                        step={1}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                        styles={{ spinButtonWrapper: { selectors: { ':after': { border: 0, borderBottom: "1px solid #707070" } } } }}
                    />


                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={enableSpecialists}
                        label="Enable Specialists"
                        onChange={onEnableSpecialistsChange}
                        styles={checkBoxStyles}
                    />

                    <ChoiceGroup
                        styles={{
                            flexContainer: {
                                display: "flex", columnGap: "10px", selectors: {
                                    ".ms-ChoiceField": {
                                        fontWeight: 'bold'
                                    }
                                }
                            }
                        }}
                        className={styles.chatSettingsSeparator}
                        defaultSelectedKey={selectSpecialistsMethod}
                        options={[
                            { key: "manual", text: "Manual" },
                            { key: "auto", text: "Auto" },
                        ]}
                        disabled={!enableSpecialists}
                        label="Select Specialist"

                        onChange={onSelectSpecialistsMethodChange}
                    />
                    <ComboBox
                        className={styles.chatSettingsSeparator}
                        label="Select Specialist"

                        // placeholder="Select Specialist"
                        disabled={!enableSpecialists || selectSpecialistsMethod === "auto"}
                        options={[
                            { key: "general_practitioners", text: "General Practitioners" },
                            { key: "respiratory_therapists", text: "Respiratory Therapists" },
                            { key: "diabetologists", text: "Diabetologists" },
                            { key: "cardiologists", text: "Cardiologists" },
                        ]}
                        defaultSelectedKey={selectedSpecialist}
                        onChange={onSelectedSpecialistChange}
                        styles={{ root: { borderBottom: "1px solid #707070" } }}

                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={enableRagFusion}
                        label="Enable Advanced Search (RAG Fusion)"
                        onChange={onEnableRagFusionChange}
                        styles={checkBoxStyles}
                    />

                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={showDeveloperOptions}
                        label="Show developer options"
                        onChange={onShowDeveloperOptionsChange}
                        styles={checkBoxStyles}
                    />
                    {showDeveloperOptions && (
                        <>
                            <Slider
                                className={styles.chatSettingsSeparator}
                                label="Max Response Tokens:"
                                min={1000}
                                max={4096}
                                step={10}
                                defaultValue={maxResponseTokens}
                                onChange={onMaxResponseTokensChange}
                                showValue
                                snapToStep
                            />
                            <Slider
                                className={styles.chatSettingsSeparator}
                                label="Relevance score:"
                                min={0}
                                max={1}
                                step={0.1}
                                defaultValue={relevanceScore}
                                onChange={onRelevanceScoreChange}
                                showValue
                                snapToStep
                            />
                            <ComboBox
                                className={styles.chatSettingsSeparator}
                                label="Document Chunking Strategy"
                                options={[
                                    { key: "all_docs_chunksize2000", text: "Static Chunking (2000 chars)" },
                                    { key: "all_docs_chunksize1000", text: "Static Chunking (1000 chars)" },
                                    { key: "all_docs_chunksize500", text: "Static Chunking (500 chars)" },
                                    { key: "all_docs_semantic_chunking", text: "Semantic Chunking" },
                                ]}
                                defaultSelectedKey={documentChunkingMethod}
                                onChange={onDocumentChunkingMethodChange}
                                styles={{ root: { borderBottom: "1px solid #707070" } }}

                            />
                            <SpinButton
                                className={styles.chatSettingsSeparator}
                                label="Max new questions for RAG Fusion:"
                                min={3}
                                max={10}
                                step={1}
                                disabled={!enableRagFusion}
                                defaultValue={maxNewQuestionsForRagFusion.toString()}
                                onChange={onMaxNewQuestionsForRagFusionChange}
                                styles={{ spinButtonWrapper: { selectors: { ':after': { border: 0, borderBottom: "1px solid #707070" } } } }}
                            />
                        </>
                    )}

                </div>
                <div className={styles.actionContainer}>
                    <Button className={styles.btnCancelOverride} appearance="subtle" disabled={props.isLoading} onClick={() => props.onCancelClick()} size="large">
                        <div className={styles.buttonText}>{"Cancel"}</div>
                    </Button>

                    <Button className={styles.btnOverride} appearance="subtle" onClick={() => onApplyClick()} disabled={props.isLoading} size="large">
                        <div className={styles.buttonText}>{"Apply"}</div>
                    </Button>
                </div>
            </div>

        </>
    );
};
