import { Outlet, NavLink, Link } from "react-router-dom";

import styles from "./Layout.module.css";

import { SettingsButton } from "../../components/SettingsButton";


// import { useLogin } from "../../authConfig";

// import { LoginButton } from "../../components/LoginButton";

import logo from "../../assets/logo.png";

export const Layout = (props:any) => {
  
    const callFunction = () => {
        // Call the parent callback function
        props.parentCallback(true);
    };

  


    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        {/* <div className={styles.headerPoweredTitle}>Powered by Gemini</div>
                        <div className={styles.headerTitle}> Clinical Advisor</div> */}

                        <img src={logo} className={styles.headerLogo} alt="" />
                    </Link>
                </div>

                <div className={styles.spacer}></div>

                <SettingsButton  className={styles.commandButton} onClick={() => callFunction()} isLoading={props.isLoading} />
             
            </header>

          
        </div>
    );
};

export default Layout;
