import { renderToStaticMarkup } from "react-dom/server";
import { ChatAppResponse, ChatAppCitations} from "../../api";


export function parseAnswerToHtml(response: ChatAppResponse, isStreaming: boolean, onCitationClicked: (citationFilePath: string) => void): string {

    let answer = response.answer.replace(/\n/g, "  \n");

    if (isStreaming) {
        return answer;
    } else {
        let citations: { [key: number]: ChatAppCitations } = {};
        if (response.citations?.length ?? 0 > 0) {

            citations = response.citations.reduce<{ [key: number]: ChatAppCitations }>((acc, citation) => {        
                acc[citation.citation_index] = citation;
                return acc;
            }, {});
        }


        const parts = answer.split(/\[([0-9]{1,2})\]/g);

        const fragments: string[] = parts.map((part, index) => {
            if (index % 2 === 0) {
                return part;
            } else {
                let citation_index = parseInt(part);

                if (citations[citation_index] === undefined) {
                    return renderToStaticMarkup(
                        <a className="supContainer">
                            <sup>{citation_index}</sup>
                        </a>
                    ) 
                } else {
                    return renderToStaticMarkup(
                        <a className="supContainer" title={citations[citation_index].name} href={citations[citation_index].source_url} target="_blank">
                            <sup>{citation_index}</sup>
                        </a>
                    ) 
                }
            }
        });

        return fragments.join(" ");
    }
}
