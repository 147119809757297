import { Stack, PrimaryButton } from "@fluentui/react";
import { ErrorCircle24Regular } from "@fluentui/react-icons";

import styles from "./Answer.module.css";

interface Props {
    error: string;
}

export const AnswerError = ({ error }: Props) => {
    const reloadPage = () => {
        window.location.reload();
    };
    
    return (
        <Stack className={styles.answerContainer} verticalAlign="space-between">
            <ErrorCircle24Regular aria-hidden="true" aria-label="Error icon" primaryFill="red" />

            <Stack.Item grow>
                <p>{error}.</p>
                <p>It may be caused by session expiration. Please reload the page to try again.</p>
            </Stack.Item>

            <PrimaryButton className={styles.retryButton} onClick={reloadPage} text="Reload" />
        </Stack>
    );
};
