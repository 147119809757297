import React, { useState, useEffect, createContext, useContext } from "react";

import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";

import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";

import { ConfigContext } from "./configContext";

import {
    Config,
    configApi,
} from "./api";

const AppInitializer = () => {
    const [config, setConfig] = useState<Config | undefined>();
    const [isConfigLoaded, setIsConfigLoaded] = useState(false);

    useEffect(() => {
        // Make the config API call and set the state to true once it's completed
        configApi()
            .then((config) => {
                setIsConfigLoaded(true);
                setConfig(config);
            })
            .catch((error) => {
                console.error("Failed to load config:", error);
                // Handle error case as needed
            });
    }, []);

    if (!isConfigLoaded) {
        return ""; // Or any other loading indicator
    }

    initializeIcons();

    const router = createHashRouter([
        {
            path: "/",
            // element: <Layout />,
            children: [
                {
                    index: true,
                    element: <Chat />
                },
                {
                    path: "*",
                    lazy: () => import("./pages/NoPage")
                }
            ]
        }
    ]);

    return (
        <React.StrictMode>
            <ConfigContext.Provider value={config}>
                <RouterProvider router={router}  />
            </ConfigContext.Provider>
        </React.StrictMode>
    );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <AppInitializer />
);



// var layout;
// layout = <Layout />;

// initializeIcons();

// const router = createHashRouter([
//     {
//         path: "/",
//         element: layout,
//         children: [
//             {
//                 index: true,
//                 element: <Chat />
//             },
//             {
//                 path: "*",
//                 lazy: () => import("./pages/NoPage")
//             }
//         ]
//     }
// ]);

// ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
//     <React.StrictMode>
//         <RouterProvider router={router} />
//     </React.StrictMode>
// );
