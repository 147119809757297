import styles from "./UserChatMessage.module.css";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

interface Props {
    message: string;
}

export const UserChatMessage = ({ message }: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.message}><ReactMarkdown rehypePlugins={[rehypeRaw]}>{message.replace(/\n/g, "<br/>")}</ReactMarkdown></div>
        </div>
    );
};
