import {  Settings24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import styles from "./SettingsButton.module.css";

interface Props {
    className?: string;
    isLoading?: boolean;
    onClick: () => void;
}



export const SettingsButton = ({ className, isLoading, onClick }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
         
            <Button icon={<Settings24Regular  />}  onClick={onClick} disabled={isLoading}>
              
            </Button>
        </div>
    );
};
